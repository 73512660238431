<!-- 入库明细 -->

<template>
  <div class="enterDetail">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="orderValue" placeholder="搜索入库单号" suffix-icon="el-icon-search" clearable
          @change="onSearch"></el-input>
        <el-input v-model="codeValue" placeholder="搜索商品条形码" suffix-icon="el-icon-search" clearable
          @change="onSearch"></el-input>
        <el-input v-model="nameValue" placeholder="搜索商品名称" suffix-icon="el-icon-search" clearable
          @change="onSearch"></el-input>
        <el-date-picker v-model="dataValue" type="daterange" popper-class="sift-data" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSelectData" clearable
          value-format="yyyy-MM-dd"></el-date-picker>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="orderNum" label="入库单号"></el-table-column>
          <!-- <el-table-column min-width="120" show-overflow-tooltip prop="productNum" label="商品编号"></el-table-column> -->
          <el-table-column min-width="120" show-overflow-tooltip prop="barCode" label="商品条形码"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="brand" label="品牌"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="factoryName" label="厂家"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="categoryName" label="商品类目"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="specs" label="规格"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="productPrice" label="成本价(元)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="productCount" label="商品数量"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="totalAmount" label="总金额(元)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="createdTime" label="入库时间"
            width="180"></el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      codeValue: "", // 搜索商品编号 -- 条形码
      nameValue: "", // 搜索商品名称
      dataValue: "", // 选择时间
      orderValue: '', // 入库单号
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    };
  },
  created() {
    this.orderValue = this.$route.query.orderNum || ''
    this.getInventoryInProduct();
  },
  methods: {
    // 获取列表
    getInventoryInProduct() {
      this.loading = true;
      let params = {
        orderNum: this.orderValue || null, // 搜索入库单号
        // productNum: this.codeValue || null, // 搜索商品编号
        barCode: this.codeValue || null, // 搜索商品编号
        productName: this.nameValue || null, // 搜索商品名称
        page: this.currentPage,
        pageSize: this.pageSize,
      };

      // 时间区间（开始时间-结束时间）
      if (this.dataValue && this.dataValue.length === 2) {
        params.startTime = this.dataValue[0];
        params.endTime = this.dataValue[1];
      } else {
        params.startTime = null;
        params.endTime = null;
      }

      this.$axios.get(this.$api.getInventoryInProduct, { params })
        .then((res) => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list;
            this.totalItemsCount = res.data.result.totalCount;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 选择时间
    handleSelectData(value) {
      this.clearData();
      this.dataValue = value;
      this.getInventoryInProduct();
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getInventoryInProduct();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getInventoryInProduct();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getInventoryInProduct();
    },
  },
};
</script>

<style scoped lang="scss">
.enterDetail {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;
      margin-left: 20px;
    }

    .el-select {
      margin-left: 20px;
    }

    /deep/ .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
    }

    /deep/.el-range-input {
      background-color: #f2f2f2;

    }
  }
}
</style>
